
import AddressPicker from '@/components/address_picker.vue'
import DeliveryAddressesEmpty from '@/components/address/delivery_addresses_empty.vue'

export default {
  components: { AddressPicker, DeliveryAddressesEmpty }

  mounted: ->
    @$store.commit('layout/page_nav', {title: @$t('addresses_page_header')})

  methods: {
    create_address: (new_address) ->
      @$store.dispatch('account/create_address', new_address)

    request_address: ->
      @$store.commit('address_picker/open')

    remove_address: (id) ->
      @$store.dispatch('account/remove_address', id)
  }
}
